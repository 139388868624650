<template>
  <div class="p404">
    <div class="num">
      403
    </div>
    <div class="text">
      Vous ne pouvez pas acceder à cette page. <br>
      <router-link
        class="link"
        :to="{ path: '/' }"
      >
        Retourner sur le site
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../../assets/style/sass/mixin";
  .p404 {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .num {
      font-size: 15rem;
      line-height: 1;
      font-weight: bold;
      color: #eee;
      @include no-select;
      padding: 1rem;
    }
    .text {
      font-size: 1.5rem;
      line-height: 1.5;
      color: #9a9a9a;
      padding: 1rem;
      @include no-select;
      .link {
        color: #686868;
        transition: all .2s linear;
        &:hover {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }

  .num {
    font-size: 20rem;
  }
</style>
